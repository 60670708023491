
import React, { useEffect } from 'react';
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { Paths } from "paths";

import Home from "pages/home";
import NotFound from "pages/404";
import ServerError from "pages/500";
import ReactGA from "react-ga4";
import AboutPage from 'pages/about';

import Signin from 'pages/signin';
import Signup from 'pages/signup';
import Profile from 'pages/profile';
import Settings from 'pages/settings';
import Billing from 'pages/billing';
import VerifyEmail from 'pages/verify_email';
import Notifications from 'pages/notifications';
import Pricing from 'pages/pricing';
import Report from 'pages/report';
import Reports from 'pages/reports';
import NewReport from 'pages/new_report';
import Terms from 'pages/terms';
import Privacy from 'pages/privacy';

const PageSwitch = () => {

  const location = useLocation();
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search});
  }, [location]);

  return (
    <Routes>
      <Route exact path={Paths.NotFound.path} element={<NotFound/>} />
      <Route exact path={Paths.ServerError.path} element={<ServerError/>} />

      <Route exact path={Paths.Home.path} element={<Home/>} />
      <Route exact path={Paths.About.path} element={<AboutPage/>} />
      <Route exact path={Paths.Terms.path} element={<Terms/>} />
      <Route exact path={Paths.Privacy.path} element={<Privacy/>} />

      <Route path={Paths.Report.path}>
        <Route path=":report_id" element={<Report/>} />
      </Route>

      <Route exact path={Paths.Signin.path} element={<Signin/>} />
      <Route exact path={Paths.Signup.path} element={<Signup/>} />
      <Route exact path={Paths.VerifyEmail.path} element={<VerifyEmail/>} />

      <Route exact path={Paths.Profile.path} element={<Profile/>} />
      <Route exact path={Paths.Settings.path} element={<Settings/>} />
      <Route exact path={Paths.Billing.path} element={<Billing/>} />
      <Route exact path={Paths.Pricing.path} element={<Pricing/>} />
      <Route exact path={Paths.Notifications.path} element={<Notifications/>} />
      <Route exact path={Paths.Reports.path} element={<Reports/>} />
      <Route exact path={Paths.NewReport.path} element={<NewReport/>} />

      <Route path="*" element={<Navigate to="/404" replace />}/>
    </Routes>
  );

}

export default PageSwitch;