import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Consts } from 'consts';
import { useTranslation } from "react-i18next";
import { Alert, Button, Container, Form } from 'react-bootstrap';

import "styles/theme.css";
import Footer from 'components/footer';
import Header from 'components/header';
import Sidebar from 'components/sidebar';
import getBackend from 'backend/backend';
import { Paths } from 'paths';
import { showConfirmation } from "components/misc";

export const ai_models = ["gpt-4o", "gpt-4o-mini", "o1-mini", "o1-preview"];
export const langs = ["undefined","english", "spanish", "french", "german"];

export const NewReportPanel = () => {
    const [urlValue, setUrlValue] = useState("");
    const [selectedModel, setSelectedModel] = useState(ai_models[0]);
    const [selectedLang, setSelectedLang] = useState(langs[0]);
    const [urlsLimit, setUrlsLimit] = useState(30);
    const [profile, setProfile] = useState(null);
    const [error, setError] = useState("");
    const [paid, setPaid] = useState(false);

    useEffect(() => {
        let canceled = false;

        const getSettings = async () => {
            const result = await getBackend().getSettings();
            if (canceled)
                return;

            if (result.error == null) {
                setSelectedLang(result.response.settings.lang);
                setSelectedModel(result.response.settings.ai_model);
                setUrlsLimit(result.response.settings.urls_limit);
                setUrlValue(result.response.settings.url);
            }
        };

        const getProfile = async () => {
            const result = await getBackend().profile();
            if (canceled)
                return;

            if (result.error == null) {
                setProfile(result.response);
            }
        };

        getProfile();
        getSettings();

        return async () => {
            canceled = true;
        }
    }, []);

    const changeUrlValue = (e) => {
        setUrlValue(e.target.value);
    };

    const normalizeUrl = (value) => {
        try {
            let newValue = value;

            if (!value.startsWith("http://") &&
                !value.startsWith("https://")) {
                newValue = "https://" + value;
            }

            const url = new URL(newValue);
            if (url.protocol === 'http:' || url.protocol === 'https:') {
                return url.origin + url.pathname;
            }
        } catch (err) {
            return "";
        }
    }

    const startReport = async () => {
        const normUrl = normalizeUrl(urlValue);
        if (normUrl === "") {
            setError("Invalid URL: " + urlValue + ". Please enter a valid website URL that starts with http:// or https://.");
            return;
        }

        const params = {
            url: normUrl,
            ai_model: selectedModel,
            lang: selectedLang,
            urls_limit: urlsLimit,
            paid: paid,
        };
        const result = await getBackend().createReport(params);
        if (result.error == null) {
            window.location.href = Paths.Report.path + "/" + result.response.id;
        } else {
            setError(result.error);
            if (profile === null && result.error.includes("unauthorized")) {
                let result = await showConfirmation("Your free usage limit has been reached. To continue using our services, please consider upgrading your account.", "Do you wan't look at our paid options?");
                if (result.isConfirmed) {
                    window.location.href = Paths.Pricing.path;
                }
            }
        }
    }

    return (
        <section className="section section-md bg-soft pt-lg-5 vh-100 d-flex" id="features">
            <Container>
                {error && <Alert variant="danger">{error}</Alert>}
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>Site URL</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter your site's URL to check for grammar errors"
                            value={urlValue}
                            onChange={changeUrlValue}
                        />
                        {!profile &&
                            <Form.Check
                                type="checkbox"
                                id="paid-report-checkbox"
                                label="Generate paid report"
                                checked={paid}
                                onChange={(e) => setPaid(e.target.checked)}
                                className="mt-2"
                            />
                        }
                    </Form.Group>
                    {(profile || paid) &&
                        <Form.Group className="mb-3">
                        <Form.Label>Language</Form.Label>
                        <Form.Select value={selectedLang} onChange={(e) => setSelectedLang(e.target.value)}>
                                    {langs.map((lang, index) => (
                                        <option key={index} value={lang}>{lang}</option>
                                    ))}
                        </Form.Select>
                        <Form.Label>Page limit</Form.Label>
                        <Form.Control
                                    type="number"
                                    placeholder="Enter pages limit"
                                    value={urlsLimit}
                                    onChange={(e) => setUrlsLimit(Number(e.target.value))}
                        />
                        <Form.Label>AI model</Form.Label>
                        <Form.Select value={selectedModel} onChange={(e) => setSelectedModel(e.target.value)}>
                                    {ai_models.map((model, index) => (
                                        <option key={index} value={model}>{model}</option>
                                    ))}
                        </Form.Select>
                        </Form.Group>
                    }
                    <Button variant="primary" className="fmxw-200 text-dark rounded animate-up-2 me-3" onClick={startReport}>
                        Analyze<span className="icon icon-xs ms-3" />
                    </Button>
                </Form>
            </Container>
        </section>
    )
}

const NewReport = () => {
    const [t] = useTranslation('common');

    return (
        <>
            <Helmet>
                <title>New Report | {Consts.ProjectName}</title>
                <meta name="description" content={t('New Report')} />
            </Helmet>
            <Header/>
                <Sidebar>
                    <Container className="my-5">
                        <h2 className="mb-4">{t('New Report')}</h2>
                        <NewReportPanel/>
                    </Container>
                </Sidebar>
            <Footer/>
            
        </>
    );
}

export default NewReport;
