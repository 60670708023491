import { React, useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
import Header from 'components/header';
import Footer from 'components/footer';
import { Consts } from "consts";
import getBackend from 'backend/backend';
import Sidebar from 'components/sidebar';
import { useTranslation } from "react-i18next";
import { unixTimestampToString } from 'components/misc';
import { Table, Button, Form, Container } from 'react-bootstrap'; // Importing necessary components

const Notifications = () => {
    const [notifications, setNotifications] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [unreadOnly, setUnreadOnly] = useState(false);
    const [selectedNotifications, setSelectedNotifications] = useState([]);
    const [t] = useTranslation('common');

    const itemsPerPage = 10;

    useEffect(() => {
        let canceled = false;

        const fetchNotifications = async () => {
            const offset = (currentPage - 1) * itemsPerPage;
            const result = unreadOnly
                ? await getBackend().getUnreadNotifications(offset, itemsPerPage)
                : await getBackend().getNotifications(offset, itemsPerPage);
            if (canceled) return;

            if (result.error == null) {
                setNotifications(result.response.notifications);
            }
        };

        fetchNotifications();

        return () => {
            canceled = true;
        };
    }, [currentPage, unreadOnly]);

    const handleUnreadToggle = () => {
        setUnreadOnly(!unreadOnly);
    };

    const handleSelectNotification = (id) => {
        setSelectedNotifications(prev =>
            prev.includes(id) ? prev.filter(nid => nid !== id) : [...prev, id]
        );
    };

    const markAsRead = async () => {
        for (const id of selectedNotifications) {
            await getBackend().markNotificationRead(id);
        }
        setNotifications(notifications.map(n =>
            selectedNotifications.includes(n.id) ? { ...n, read: true } : n
        ));
        setSelectedNotifications([]);
    };

    const paginatedNotifications = notifications.slice(0, itemsPerPage);

    return (
        <>
            <Helmet>
                <title>{t('Notifications')} | {Consts.ProjectName}</title>
                <meta name="description" content={`${t('Notifications')} ${Consts.ProjectName}`} />
            </Helmet>
            <Header />

            <Sidebar>
                <Container className="my-5">

                <h2 className="mb-4">{t('Notifications')}</h2>

                <div>
                    <Form.Check 
                        type="checkbox" 
                        label={t('Show Unread Only')} 
                        checked={unreadOnly} 
                        onChange={handleUnreadToggle} 
                    />
                </div>

                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>{t('Select')}</th>
                            <th>{t('Timestamp')}</th>
                            <th>{t('Severity')}</th>
                            <th>{t('Message')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {paginatedNotifications.map(notification => (
                            <tr key={notification.id}>
                                <td>
                                    <Form.Check 
                                        type="checkbox" 
                                        checked={selectedNotifications.includes(notification.id)}
                                        onChange={() => handleSelectNotification(notification.id)}
                                    />
                                </td>
                                <td>{unixTimestampToString(notification.timestamp)}</td>
                                <td>{notification.severity}</td>
                                <td>{notification.message}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>

                <Button variant="primary" onClick={markAsRead}>{t('Mark as Read')}</Button>

                <div className="mt-3">
                    <Button 
                        variant="secondary" 
                        disabled={currentPage === 1} 
                        onClick={() => setCurrentPage(currentPage - 1)}
                    >
                        {t('Previous')}
                    </Button>
                    <Button 
                        variant="secondary" 
                        disabled={currentPage * itemsPerPage >= notifications.length} 
                        onClick={() => setCurrentPage(currentPage + 1)}
                    >
                        {t('Next')}
                    </Button>
                </div>
                </Container>
            </Sidebar>

            <Footer />
        </>
    );
};

export default Notifications;
