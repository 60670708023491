import devmode from './env';
import { v4 as uuidv4 } from 'uuid';

class HttpClient {
    constructor(host) {
        this.post = this.post.bind(this);
        this.get = this.get.bind(this);
        this.host = host;
        this.devmode = devmode();
    }

    async try_decode_error(resp) {
        try {
            const response = await resp.json();
            if (response.error != null && response.error.length) {
                return response.error
            }
        } catch (e) {
        }

        return ""
    }

    async post(url = '', data = {}, headers = {}) {
        const full_url = this.host + url;
        const request_id = uuidv4();

        try {
            headers["X-RequestId"] = request_id;

            if (this.devmode)
                console.log("post " + full_url + " " + JSON.stringify(data));

            headers["Content-Type"] = "application/json";

            // Default options are marked with *
            const resp = await fetch(full_url, {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'omit', // include, *same-origin, omit
                headers: headers,
                redirect: 'follow', // manual, *follow, error
                referrerPolicy: 'no-referrer', // no-referrer, *client
                body: JSON.stringify(data) // body data type must match "Content-Type" header
            });

            if (!resp.ok) {
                let message = `http error: ${resp.status}`;
                const error_message = await this.try_decode_error(resp);
                if (error_message !== "")
                    message += ": " + error_message;
                throw new Error(message);
            }

            const response = await resp.json();
            if (this.devmode)
                console.log("post " + full_url + " " + JSON.stringify(data) + " " + JSON.stringify(response));

            if (response.error != null && response.error.length) {
                throw new Error(response.error);
            }

            return {response: response, error: null};
        } catch (error) {
            if (this.devmode)
                console.log("post " + full_url + " " + JSON.stringify(data) + " error: " + error + " rid: " + request_id);

            return {response: null, error: "rid: " + request_id + " " + error};
        }
    }

    async getBlob(url = '', headers = {}) {
        const full_url = this.host + url;
        const request_id = uuidv4();

        try {
            headers["X-RequestId"] = request_id;

            if (this.devmode)
                console.log("get " + full_url);

            // Default options are marked with *
            const resp = await fetch(full_url, {
                method: 'GET', // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'omit', // include, *same-origin, omit
                headers: headers,
                redirect: 'follow', // manual, *follow, error
                referrerPolicy: 'no-referrer', // no-referrer, *client
            });
            if (!resp.ok) {
                const message = `http error: ${resp.status}`;
                throw new Error(message);
            }

            const response = await resp.blob();
            return {response: response, error: null};
        } catch (error) {
            if (this.devmode)
                console.log("get " + full_url + " error: " + error + " rid: " + request_id);

            return {response: null, error: "rid: " + request_id + " " + error};
        }
    }

    async get(url = '', headers = {}, log_respone = true) {
        const full_url = this.host + url;
        const request_id = uuidv4();
        try {
            headers["X-RequestId"] = request_id;

            if (this.devmode)
                console.log("get " + full_url);

            // Default options are marked with *
            const resp = await fetch(full_url, {
                method: 'GET', // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'omit', // include, *same-origin, omit
                headers: headers,
                redirect: 'follow', // manual, *follow, error
                referrerPolicy: 'no-referrer', // no-referrer, *client
            });
            if (!resp.ok) {
                let message = `http error: ${resp.status}`;
                const error_message = await this.try_decode_error(resp);
                if (error_message !== "")
                    message += ": " + error_message;
                throw new Error(message);
            }

            const response = await resp.json();
            if (this.devmode && log_respone)
                console.log("get " + full_url + " " + JSON.stringify(response));

            if (response.error != null && response.error.length) {
                throw new Error(response.error);
            }

            return {response: response, error: null};
        } catch (error) {
            if (this.devmode)
                console.log("get " + full_url + " error: " + error + " rid: " + request_id);

            return {response: null, error: "rid: " + request_id + " " + error};
        }
    }

}

export default HttpClient