import React from 'react';
import { Container} from 'react-bootstrap';
import {Helmet} from "react-helmet";
import Header from 'components/header';
import Footer from 'components/footer';
import { Consts } from "consts";
import { useTranslation } from "react-i18next";
import { PricingPresentation } from './billing';

const Pricing = () => {
  const [t] = useTranslation('common');

  return (
    <div>
        <Helmet>
            <title>{t('Pricing')} | {Consts.ProjectName}</title>
            <meta name="description" content="Pricing for an AI-powered automated service to check your website's text for grammatical errors and typos." />
        </Helmet>
        <Header/>
            <Container className="min-vh-100 my-5">
                <PricingPresentation/>
            </Container>
        <Footer/>
    </div>
  );
};

export default Pricing;