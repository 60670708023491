import React from 'react';
import { Container, Card} from 'react-bootstrap';
import {Helmet} from "react-helmet";
import Header from 'components/header';
import Footer from 'components/footer';
import { Consts } from "consts";
import { useTranslation } from "react-i18next";

const Privacy = () => {
  const [t] = useTranslation('common');

  return (
    <div>
        <Helmet>
            <title>{t('About')} | {Consts.ProjectName}</title>
            <meta name="description" content={`${t('About')} ${Consts.ProjectName}`} />
        </Helmet>
        <Header/>
        <Container className="min-vh-100 my-5">
            <Card>
                <Card.Body>
                <h1 className="mb-4">Privacy Policy</h1>
                <p><em>Last Updated: October 31, 2024</em></p>
                <p>
                    {Consts.ProjectName} ("we," "us," or "our") is committed to protecting your privacy. This Privacy Policy outlines how we collect, use, and safeguard your information when you use our web service ("Service").
                </p>
                <hr />

                <h3>1. Information We Collect</h3>
                <p>
                    We only collect the following information:
                </p>
                <ul>
                    <li>
                    <strong>Email Address:</strong> When you sign up for our Service, we collect your email address, which we use as your user ID.
                    </li>
                </ul>

                <h3>2. Use of JavaScript</h3>
                <p>
                    Our Service utilizes JavaScript to provide interactive features and improve user experience. JavaScript is essential for the functionality of our Service.
                </p>

                <h3>3. How We Use Your Information</h3>
                <p>
                    We use your email address solely for identification purposes within our Service. We do not use your email for marketing, nor do we share it with third parties.
                </p>

                <h3>4. Data Security</h3>
                <p>
                    We implement reasonable security measures to protect your email address from unauthorized access, alteration, disclosure, or destruction. However, no method of transmission over the internet is 100% secure.
                </p>

                <h3>5. Cookies and Tracking Technologies</h3>
                <p>
                    We do not use cookies or any other tracking technologies to collect additional personal information.
                </p>

                <h3>6. Third-Party Services</h3>
                <p>
                    Our Service does not integrate with third-party services that collect or process your personal information.
                </p>

                <h3>7. Children's Privacy</h3>
                <p>
                    Our Service is not intended for individuals under the age of 13. We do not knowingly collect personal information from children under 13.
                </p>

                <h3>8. Changes to This Privacy Policy</h3>
                <p>
                    We may update our Privacy Policy from time to time. Any changes will be posted on this page with an updated revision date.
                </p>

                <h3>9. Contact Information</h3>
                <p>
                    If you have any questions about this Privacy Policy, please contact us at:
                </p>
                <ul>
                    <li><strong>Email:</strong> {Consts.ContactEmail}</li>
                </ul>

                <hr />
                <p>
                    By using our Service, you acknowledge that you have read and understand this Privacy Policy.
                </p>
                </Card.Body>
            </Card>
        </Container>
        <Footer/>
    </div>
  );
};

export default Privacy;