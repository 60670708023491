import React from "react";
import { Col, Row, Button, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Paths } from "paths";
import Footer from "components/footer";
import Header from "components/header";
import { Helmet } from "react-helmet";
import { Consts } from "consts";

import "styles/theme.css";

const ServerError = () => {
  const { t } = useTranslation('common');

  return (
    <>
      <Helmet>
        <title>{Consts.ProjectName} {t('500 Internal Server Error')}</title>
        <meta name="description" content={t('500 Internal Server Error')} />
      </Helmet>
      <Header/>
      <section className="vh-100 d-flex align-items-center justify-content-center">
        <Container>
          <Row className="align-items-center">
            <Col xs={12} className="text-center d-flex align-items-center justify-content-center">
              <div>
                <p className="lead my-4 message-text-color">
                  {t('Oops! Something went wrong on the server side. It\'s always time for a coffee break. We should be back by the time you finish your coffee.')}
                </p>
                <Button as={Link} className="d-inline-flex align-items-center justify-content-center mb-4" to={Paths.Home.path}>
                  {t('Teleport home')}
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer/>
    </>
  );
};

export default ServerError;
