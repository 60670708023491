import React from "react";
import {Navbar, Nav, Image} from 'react-bootstrap';
import "styles/theme.css";
import Logo from "assets/logo2.svg"
import { NavLink } from 'react-router-dom';
import { Paths } from "paths";
import { useEffect, useState } from 'react';
import getBackend from "backend/backend";
import { useTranslation } from "react-i18next";
import { googleLogout } from '@react-oauth/google';
import { Consts } from "consts";

const Header = (props) => {
  const { t } = useTranslation('common');

  const [profile, setProfile] = useState({});

  useEffect(() => {
      let canceled = false;

      const getProfile = async () => {
          const result = await getBackend().profile();
          if (canceled)
            return;

          if (result.error == null) {
              setProfile(result.response);
          }
      };

      getProfile();

      return async () => {
          canceled = true;
      }
  }, []);

  const handleSignOut = async () => {
    const result = await getBackend().signout();
    if (result.error) {
        window.location.href = Paths.ServerError.path;
    } else {
        if (result.response.auth_type === "google") {
            googleLogout();
        }
        window.location.href = Paths.Home.path;
    }
  };

  return (
    <>
        <header className="header mt-auto py-3 bg-light border-bottom">
        <Navbar expand="lg" className="ml-4">
            <Navbar.Brand as={NavLink} to="/">
            <Image
                src={Logo}
                alt="Logo"
                style={{
                width: '60px',
                height: 'auto',
                filter: 'drop-shadow(0 0 10px rgba(255, 255, 255, 0.8))',
                }}
            />
            <span className="ml-2 header-text">{Consts.ProjectName}</span>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
                <Nav.Link as={NavLink} to={Paths.Home.path} className="header-text">{t('Home')}</Nav.Link>
                <Nav.Link as={NavLink} to={Paths.About.path} className="header-text">{t('About')}</Nav.Link>
                <Nav.Link as={NavLink} to={Paths.Pricing.path} className="header-text">{t('Pricing')}</Nav.Link>
                {profile.name ? (
                    <>
                    <Nav.Link as={NavLink} to={Paths.Profile.path} className="header-text">{t('Profile')}</Nav.Link>
                    <Nav.Link onClick={handleSignOut} className="header-text">{t('Sign out')}</Nav.Link>
                    </>
                    ) : (
                        <>
                        <Nav.Link as={NavLink} to={Paths.Signup.path} className="header-text">{t('Sign Up')}</Nav.Link>
                        <Nav.Link as={NavLink} to={Paths.Signin.path} className="header-text">{t('Sign In')}</Nav.Link>
                        </>    
                    )
                }

            </Nav>
            </Navbar.Collapse>
        </Navbar>
        </header>
    </>
)
}

export default Header;
