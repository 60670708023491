import React from 'react';
import { Container, Row, Col, Alert, Form, Button} from 'react-bootstrap';
import {Helmet} from "react-helmet";
import Header from 'components/header';
import Footer from 'components/footer';
import { Consts } from "consts";
import { useState } from 'react';
import getBackend from 'backend/backend';
import { Paths } from 'paths';
import { GoogleLogin } from '@react-oauth/google';
import { DividerOr } from 'components/misc';
import { useTranslation } from "react-i18next";

const Signup = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [t] = useTranslation('common');

    const doSignin = async (authType, name, password) => {
        setError("");
        setLoading(true);

        const result = await getBackend().signin({auth_type: authType, name: name, password: password});
        if (result.error != null) {
            setError(result.error);
        } else {
            window.location.href = Paths.Profile.path;
        }

        setLoading(false);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        setError("");
        setLoading(true);

        const result = await getBackend().signup({auth_type: "email", name: email, password: password});
        if (result.error != null) {
            setError(result.error);
        } else {
            window.location.href = Paths.VerifyEmail.path;
        }

        setLoading(false);
    };

    return (
    <>
        <Helmet>
            <title>{t('Signup')} | {Consts.ProjectName}</title>
            <meta name="description" content={`${t('Signup')} ${Consts.ProjectName}`} />
        </Helmet>
        <Header/>
        <Container>
            <Row className="justify-content-md-center vh-100">
                <Col md={6}>
                <h2 className="my-4 text-center">{t('Sign Up')}</h2>
                {error && <Alert variant="danger">{error}</Alert>}
                <GoogleLogin
                    onSuccess={credentialResponse => {
                        doSignin("google", "", credentialResponse.credential);
                    }}
                    onError={() => {
                        setError(t('Google signin: something went wrong'));
                    }}
                />
                <DividerOr/>
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="name">
                    <Form.Label>{t('Email')}</Form.Label>
                    <Form.Control
                        type="text"
                        className="custom-input"
                        placeholder={t('Enter your email')}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    </Form.Group>

                    <Form.Group controlId="password">
                    <Form.Label>{t('Password')}</Form.Label>
                    <Form.Control
                        type="password"
                        className="custom-input"
                        placeholder={t('Enter your password')}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                    </Form.Group>

                    <Button className="btn-custom" variant="primary" type="submit" disabled={loading}>
                    {loading ? t('Signing Up...') : t('Sign Up')}
                    </Button>
                </Form>
                </Col>
            </Row>
        </Container>
        <Footer/>
    </>
  );
};

export default Signup;