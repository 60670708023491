import {Helmet} from "react-helmet";
import Header from 'components/header';
import Footer from 'components/footer';
import { Consts } from "consts";
import React, { useState, useEffect } from "react";

import { Container, Table, Card, ProgressBar, Alert, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';


import { Paths } from "paths";
import { useTranslation } from "react-i18next";

import getBackend from "backend/backend";
import { capitalizeFirstLetter } from "components/misc";
import Sidebar from "components/sidebar";

const AlertsTable = (props) => {
    const { alerts = [] } = props;

    const severityToStatus = (severity) => {
        switch (severity) {
        case "critical":
            return "danger";
        case "high":
            return "warning";
        case "medium":
            return "info";
        case "low":
            return "muted";
        default:
            return "primary";
        }
    }

    const AlertTableRow = (props) => {
        const { alert } = props;
        const status = severityToStatus(alert.severity);

        const shortenString = (str, maxLength) => {
            if (str.length <= maxLength) {
                return str;
            }
            return str.substring(0, maxLength - 3) + "...";
        }

        const getAlertDetails = (alert) => {
            return shortenString(alert.description, 200);
        }

        return (
            <tr className="border-bottom">  
                <td>
                <span className={`fw-normal text-${status}`}>
                    {capitalizeFirstLetter(alert.severity)}
                </span>
                </td>

                <td><span className="fw-normal"><a href={alert.url}>{alert.url}</a></span></td>

                <td title={alert.description}>
                <span className="fw-normal">{getAlertDetails(alert)}</span>&nbsp;
                </td>

            </tr>
        );
    };

    return (
          <Table hover className="user-table align-items-center">
            <thead>
              <tr>
                <th className="border-bottom">Severity</th>
                <th className="border-bottom">Page</th>
                <th className="border-bottom">Description</th>
              </tr>
            </thead>
            <tbody className="border-0">
              {alerts.map(u => <AlertTableRow key={`alert-${u.id}`} alert={u} />)}
            </tbody>
          </Table>
    );
  };


const ReportProgressBar = (props) => {
    return (
        <ProgressBar animated now={props.progress} label={`${props.progress}%`} style={{ minHeight: '30px' }}/>
    );
}

const ReportCard = (props) => {

    return (
        <Container className="min-vh-100 my-5">
            <section className="justify-content-center">
                <Card>
                    <Card.Header>
                        <Card.Title><h1>Site analysis <a href={props.url}>{props.url}</a></h1></Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <h6>
                            Pages Scanned: {props.pages_count}
                        </h6>
                        <h6>
                            AI Model: {props.ai_model}
                        </h6>
                        <h6>
                            Language: {props.lang}
                        </h6>
                        <h6>
                            Grammar Issues Found: <span className="text-danger"><strong>Critical:</strong> {props.critical_errors_count}</span> | <span className="text-warning"><strong>High:</strong> {props.high_errors_count}</span> | <span className="text-info"><strong>Medium:</strong> {props.medium_errors_count}</span> | <span className="text-muted"><strong>Low:</strong> {props.low_errors_count}</span>
                        </h6>
                        {props.progress < 100 &&
                            <>
                            {props.status !== "" &&
                                <h5>Status: {props.status}. Please wait...</h5>
                            }
                            <ReportProgressBar progress={props.progress}/>
                            </>
                        }
                        {props.error !== "" &&
                            <Alert variant="danger" className="text-center">
                                Error: {props.error}
                            </Alert>
                        }
                        <AlertsTable alerts={props.issues}/>
                        {(props.hidden && props.payment_link !== "") &&
                            <Alert variant="info" className="text-center">
                                <h5>Other grammar issues are hidden. Complete the payment to view them.</h5>
                                <Button variant="primary" className="mt-3" as={Link} to={props.payment_link}>
                                    Unlock Grammar Issues <span className="icon icon-xs ms-2" />
                                </Button>
                            </Alert>
                        }
                    </Card.Body>
                </Card>
            </section>
        </Container>
    )
}

const Report = () => {
    const {report_id} = useParams();

    const [t] = useTranslation('common');
    const [issues, setIssues] = useState([]);
    const [progress, setProgress] = useState(3);
    const [url, setUrl] = useState("");
    const [pagesCount, setPagesCount] = useState(0);
    const [error, setError] = useState("");
    const [status, setStatus] = useState("loading");
    const [profile, setProfile] = useState(null);
    const [hidden, setHidden] = useState(false);
    const [paymentLink, setPaymentLink] = useState("");
    const [criticalErrorsCount, setCriticalErrorsCount] = useState(0);
    const [highErrorsCount, setHighErrorsCount] = useState(0);
    const [mediumErrorsCount, setMediumErrorsCount] = useState(0);
    const [lowErrorsCount, setLowErrorsCount] = useState(0);
    const [aiModel, setAiModel] = useState("");
    const [lang, setLang] = useState("");
    const sortIssuesBySeverity = (issues) => {
        const severityOrder = ['critical', 'high', 'medium', 'low'];
        return issues.sort((a, b) => severityOrder.indexOf(a.severity) - severityOrder.indexOf(b.severity));
    };

    useEffect(() => {
      let canceled = false;
  
        const getProfile = async () => {
            const result = await getBackend().profile();
            if (canceled)
                return;

            if (result.error == null) {
                setProfile(result.response);
            }
        };

        const getReport = async () => {
            const result = await getBackend().getReport(report_id);
            if (canceled)
                return {progress: 0, error: ""};

            if (result.error == null) {
                setUrl(result.response.url);
                setProgress(result.response.progress);
                setPagesCount(result.response.pages_count);
                setError(result.response.report_error);
                setStatus(result.response.status);
                setHidden(result.response.hidden);
                setPaymentLink(result.response.payment_link || "");
                setCriticalErrorsCount(result.response.critical_errors_count);
                setHighErrorsCount(result.response.high_errors_count);
                setMediumErrorsCount(result.response.medium_errors_count);
                setLowErrorsCount(result.response.low_errors_count);
                setAiModel(result.response.ai_model);
                setLang(result.response.lang);
                setIssues(sortIssuesBySeverity(result.response.issues).map((u, index) => ({ ...u, id: index})));

                return {progress: result.response.progress, error: result.response.report_error};
            } else {
                window.location.href = Paths.NotFound.path;
            }
        };

        getProfile();

        const timer = setInterval(async () => {
            if (!canceled) {
                const {progress, error} = await getReport();
                if (progress >= 100 || error !== "") {
                    clearInterval(timer);
                }
            }
        }, 1000); // Update every 1 second

        return async () => {
            clearInterval(timer);
            canceled = true;
        }
  }, [report_id]);

  return (
    <div>
        <Helmet>
            <title>{t('Report')} | {Consts.ProjectName}</title>
            <meta name="description" content={`${t('Report')} ${Consts.ProjectName}`} />
        </Helmet>
        <Header/>
            {profile ?
                <Sidebar>
                    <ReportCard
                        url={url}
                        status={status}
                        progress={progress}
                        error={error}
                        issues={issues}
                        hidden={hidden}
                        payment_link={paymentLink}
                        critical_errors_count={criticalErrorsCount}
                        high_errors_count={highErrorsCount}
                        medium_errors_count={mediumErrorsCount}
                        low_errors_count={lowErrorsCount}
                        ai_model={aiModel}
                        pages_count={pagesCount}
                        lang={lang}
                    />
                </Sidebar>
                :
                <ReportCard
                    url={url}
                    status={status}
                    progress={progress}
                    error={error}
                    issues={issues}
                    hidden={hidden}
                    payment_link={paymentLink}
                    critical_errors_count={criticalErrorsCount}
                    high_errors_count={highErrorsCount}
                    medium_errors_count={mediumErrorsCount}
                    low_errors_count={lowErrorsCount}
                    ai_model={aiModel}
                    pages_count={pagesCount}
                    lang={lang}
                />
            }
        <Footer/>
    </div>
  );
};

export default Report;