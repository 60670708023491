import React from 'react';
import { Helmet } from 'react-helmet';
import { Consts } from 'consts';
import { useTranslation } from "react-i18next";
import { Col, Row, Container } from 'react-bootstrap';
import { FaRobot, FaSearch, FaFileAlt } from 'react-icons/fa';

import "styles/theme.css";
import Footer from 'components/footer';
import Header from 'components/header';
import { NewReportPanel } from './new_report';

const FeatureCard = ({ title, description, icon: Icon }) => (
    <Col md={4} className="mb-4">
        <div className="card h-100 shadow-sm">
            <div className="card-body text-center">
                <div className="feature-icon mb-3">
                    <Icon className="fs-2 text-primary" />
                </div>
                <h5 className="card-title">{title}</h5>
                <p className="card-text text-muted">{description}</p>
            </div>
        </div>
    </Col>
);

const features = [
    {
        title: "AI-Powered Analysis",
        description: "Advanced artificial intelligence algorithms to detect grammar and spelling issues with high accuracy",
        icon: FaRobot
    },
    {
        title: "Full Website Scan",
        description: "Comprehensive scanning of all your website pages to catch every potential error",
        icon: FaSearch
    },
    {
        title: "Detailed Reports",
        description: "Get detailed reports with specific suggestions and improvements for your content",
        icon: FaFileAlt
    }
];

const Home = () => {
    const [t] = useTranslation('common');

    return (
        <>
            <Helmet>
                <title>Home | {Consts.ProjectName}</title>
                <meta name="description" content={t("AI-powered automated service to check your website's text for grammatical errors and typos")} />
            </Helmet>
            <Header/>
                <section className="section-header pt-3 pb-lg-3 text-white overflow-hidden" id="home">
                    <Container>
                    <Row>
                        <Col xs={12} className="text-center">
                        <h1 className="fw-bolder text-primary">
                            {Consts.ProjectName}
                        </h1>
                        <p className="text-muted fw-light mb-5 h5">Use our AI-powered automated service to check your website's text for grammatical errors and typos</p>
                        </Col>
                    </Row>
                    </Container>
                </section>
                <section className="py-1">
                    <Container>
                        <Row>
                            {features.map((feature, index) => (
                                <FeatureCard key={index} {...feature} />
                            ))}
                        </Row>
                    </Container>
                </section>
                <NewReportPanel/>
            <Footer/>
            
        </>
    );
}

export default Home;