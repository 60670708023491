import { React, useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
import Header from 'components/header';
import Footer from 'components/footer';
import { Consts } from "consts";
import getBackend from 'backend/backend';
import Sidebar from 'components/sidebar';
import { useTranslation } from "react-i18next";
import { unixTimestampToString } from 'components/misc';
import { Table, Button, Container } from 'react-bootstrap'; // Importing necessary components
import { Paths } from 'paths';

const Reports = () => {
    const [reports, setReports] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [t] = useTranslation('common');

    const itemsPerPage = 10;

    useEffect(() => {
        let canceled = false;

        const fetchReports = async () => {
            const offset = (currentPage - 1) * itemsPerPage;
            const result = await getBackend().getReports(offset, itemsPerPage);
            if (canceled) return;

            if (result.error == null) {
                setReports(result.response.reports);
            }
        };

        fetchReports();

        return () => {
            canceled = true;
        };
    }, [currentPage]);

    const paginatedReports = reports.slice(0, itemsPerPage);

    return (
        <>
            <Helmet>
                <title>{t('Reports')} | {Consts.ProjectName}</title>
                <meta name="description" content={`${t('Reports')} ${Consts.ProjectName}`} />
            </Helmet>
            <Header />

            <Sidebar>
                <Container className="my-5">

                <h2 className="mb-4">{t('Reports')}</h2>

                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>{t('ID')}</th>
                            <th>{t('Url')}</th>
                            <th>{t('Status')}</th>
                            <th>{t('Progress')}</th>
                            <th>{t('Created')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {paginatedReports.map(report => (
                            <tr key={report.id}>
                                <td><a href={Paths.Report.path + "/" + report.id}>{report.id}</a></td>
                                <td>{report.url}</td>
                                <td>{report.status}</td>
                                <td>{report.progress}</td>
                                <td>{unixTimestampToString(report.created_ts/1000000)}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>

                <div className="mt-3">
                    <Button 
                        variant="secondary" 
                        disabled={currentPage === 1} 
                        onClick={() => setCurrentPage(currentPage - 1)}
                    >
                        {t('Previous')}
                    </Button>
                    <Button 
                        variant="secondary" 
                        disabled={currentPage * itemsPerPage >= reports.length} 
                        onClick={() => setCurrentPage(currentPage + 1)}
                    >
                        {t('Next')}
                    </Button>
                </div>
                </Container>
            </Sidebar>

            <Footer />
        </>
    );
};

export default Reports;
