import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import App from './app';
import "styles/theme.css";
import Helmet from 'react-helmet';
import { BrowserRouter } from 'react-router-dom';

import { createRoot } from 'react-dom/client';
import { Consts } from 'consts';
import { GoogleOAuthProvider } from '@react-oauth/google';

import ReactGA from "react-ga4";
import getBackend from 'backend/backend';

import {I18nextProvider} from "react-i18next";
import i18next from "i18next";

import common_en from "./translations/en/common.json";
import common_es from "./translations/es/common.json";
import common_fr from "./translations/fr/common.json";
import common_de from "./translations/de/common.json";

ReactGA.initialize(Consts.ProjectGoogleAnalytics);

const detectLang = () => {
  const language = navigator.language || navigator.userLanguage; // Get the browser's language setting
  const supportedLanguages = ['en', 'es', 'fr', 'de']; // List of supported languages

  // Check if the browser's language is supported, otherwise default to 'en'
  return supportedLanguages.includes(language) ? language : 'en';
};

i18next.init({
  interpolation: { escapeValue: false },  // React already does escaping
  lng: detectLang(),                              // language to use
  fallbackLng: 'en',
  resources: {
      en: {
        common: common_en               // 'common' is our custom namespace
      },
      es: {
        common: common_es
      },
      fr: {
        common: common_fr
      },
      de: {
        common: common_de
      },
  },
});

class AuthTokenRefresher extends React.Component {
  intervalId = null;

  async renewToken() {
    await getBackend().renewToken();
  }

  componentDidMount() {
    this.intervalId = setInterval(this.renewToken, 3600000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  render() {
      return (
          <>
          </>
      )
  }
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <>
      <Helmet>
        <title>{Consts.ProjectName}</title>
        <meta name="description" content={Consts.ProjectContent} />
      </Helmet>
      <GoogleOAuthProvider clientId={Consts.GoogleOAuthProviderClientId}>
        <AuthTokenRefresher/>
        <I18nextProvider i18n={i18next}>
        <BrowserRouter>
          <App/>
        </BrowserRouter>
        </I18nextProvider>
      </GoogleOAuthProvider>
    </>
);
